<template>
  <v-container class="mt-0 pt-0">
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card outlined class="mx-auto" elevation="2">
          <v-card-title style="display: flex; justify-content: center">
            <h4>Solicitações por dia - ({{week}})</h4>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2"
                  color="primary"
                  style="cursor: pointer;"
                >
                  mdi-information
                </v-icon>
              </template>
              <span>
                Esta consulta recupera o número de solicitações abertas e fechadas por dia. A consulta:
                <ul>
                  <li>Aberto: Solicitações criadas durante o período ({{week}}).</li>
                  <li>Fechado: Solicitações que foram atualizadas para o status "Finalizado" no período de ({{week}}).</li>
                </ul>
              </span>
            </v-tooltip>
          </v-card-title>
          <v-col v-if="loading" class="col-md-12 d-flex justify-center align-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-col>
          <v-col v-if="showErrorMessage" class="col-md-12 d-flex justify-center align-center">
            {{messageError}}
          </v-col>
          <div v-show="!loading" style="position: relative; height: 100%; width: 100%">
            <canvas ref="solicitationWeek"></canvas>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { mapActions, mapState } from "vuex";

export default {
  props: {
    selectedDate: {
      type: Object,
    }
  },
  data() {
    return {
      loading: true,
      week: '',
      messageError: '',
      showErrorMessage: false,
      chartInstance: null
    };
  },
  async mounted() {},
  computed: {
    ...mapState("dashboard", ["graphWeekOpenClosed"])
  },
  methods: {
    ...mapActions("dashboard", ['loadGraphWeekOpenClosed']),

    async loadDataComponent() {
      try {
        this.loading = true;
        if (this.chartInstance) {
          this.chartInstance.destroy();
        }
        await this.loadGraphWeekOpenClosed(this.selectedDate);
        this.graph(this.graphWeekOpenClosed.data);
        this.week = this.graphWeekOpenClosed.week;
      } catch (error) {
        this.loading = false;
        this.showErrorMessage = true;
        this.messageError = error.message;
        this.$swal("Oops...", error.message, "error");
      }
    },

    graph(data) {
      const labels = Object.keys(data);
      const createdData = labels.map(label => data[label].created);
      const closedData = labels.map(label => data[label].closed);
      const maxValue = Math.max(...createdData, ...closedData);
      const maxScale = Math.ceil(maxValue / 5) * 5;

      const ctx = this.$refs.solicitationWeek;

      this.chartInstance = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Aberto',
              data: createdData,
              backgroundColor: 'rgb(46, 58, 89)',
              borderColor: 'rgb(46, 58, 89)',
              borderWidth: 1
            },
            {
              label: 'Fechado',
              data: closedData,
              backgroundColor: 'rgb(231,80,90)',
              borderColor: 'rgb(231,80,90)',
              borderWidth: 1
            }
          ]
        },
        options: {
          plugins: {
            datalabels: {
              anchor: 'end',
              align: 'end',
              formatter: (value, context) => {
                return value;
              },
              font: {
                weight: 'bold'
              },
            }
          },
          scales: {
            y: {
              max: maxScale,
              beginAtZero: true,
              title: {
                display: true,
                text: 'Número de Solicitações'
              },
              ticks: {
                callback: function(value) {
                  return Number.isInteger(value) ? value : null;
                }
              }
            },
            x: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Dia'
              }
            }
          }
        },
        plugins: [ChartDataLabels]
      });
      this.loading = false;
    },
  },

  watch: {
    async selectedDate(newDate) {
      await this.loadDataComponent(newDate);
    }
  },
};
</script>
<style scoped></style>
