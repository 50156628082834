<template>
  <v-container class="mt-0 pt-0">
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card outlined class="mx-auto" elevation="2">
          <v-card-title style="display: flex; justify-content: center;">
            <h4>Solicitações por Status - ({{day}})</h4>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2"
                  color="primary"
                  style="cursor: pointer;"
                >
                  mdi-information
                </v-icon>
              </template>

              <span>
                Esta consulta recupera o número de solicitações abertas por Status no período de ({{day}}).
              </span>
            </v-tooltip>
          </v-card-title>
          <v-col v-if="loading" class="col-md-12 d-flex justify-center align-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-col>
          <v-col v-if="showErrorMessage" class="col-md-12 d-flex justify-center align-center">
            {{messageError}}
          </v-col>
          <div v-show="!loading" style="position: relative; height: 100%; width: 100%">
            <canvas ref="totalSolicitationStatus"></canvas>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { mapActions, mapState } from "vuex";

export default {
  props: {
    selectedDate: {
      type: Object,
    },
    day: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      loading: true,
      messageError: '',
      chartInstance: null,
      showErrorMessage: false
    };
  },
  async mounted() {},
  computed: {
    ...mapState("dashboard", ["totalStatus"])
  },
  methods: {
    ...mapActions("dashboard", ['loadTotalStatus']),

    async loadDataComponent() {
      try {
        this.loading = true;
        if (this.chartInstance) {
          this.chartInstance.destroy();
        }
        await this.loadTotalStatus(this.selectedDate);
        this.graph(this.totalStatus.data);
        this.day = this.totalStatus.interval;
      } catch (error) {
        this.loading = false;
        this.showErrorMessage = true;
        this.messageError = error.message;
        this.$swal("Oops...", error.message, "error");
      }
    },

    graph(data) {
      const labels = Object.keys(data);
      const total = labels.map(label => data[label].total);
      const colors = labels.map(label => data[label].color);

      const ctx = this.$refs.totalSolicitationStatus;

      this.chartInstance = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: labels,
          datasets: [
            {
              data: total,
              backgroundColor: colors,
              borderColor: colors,
              borderWidth: 1,
              hoverOffset: 20
            },
          ],
        },
        options: {
          plugins: {
            datalabels: {
              anchor: 'center',
              align: 'center',
              formatter: (value, context) => {
                return value;
              },
              font: {
                size: 15,
                weight: 'bold'
              },
            }
          },
          responsive: true,
          maintainAspectRatio: false, // Desativa a manutenção automática da proporção
          scales: {
            y: {
              ticks: {
                callback: function(value) {
                  return Number.isInteger(value) ? value : null;
                },
              }
            },
            x: {
              ticks: {
                callback: function(value) {
                  return Number.isInteger(value) ? value : null;
                }
              }
            }
          }
        },
        plugins: [ChartDataLabels]
      });
      this.loading = false;
    }
  },
  watch: {
    async selectedDate(newDate) {
      await this.loadDataComponent(newDate);
    }
  },
};
</script>
<style scoped>
.chartjs-datalabels {
  font-size: 16px !important; /* Ajuste o tamanho da fonte conforme necessário */
  font-weight: bold; /* Opcional: define a fonte como negrito */
}
</style>
